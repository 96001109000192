import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import useHover from '@hzdg/use-hover';
import {BackLink} from '@components/Link';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {HeroSectionCallout} from '@components/SectionCallout';
import Hero from '@components/Hero';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {usePageContext} from '@components/Page';
import {
  IconListSection,
  TestimonialSection,
  FullWidthImageSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections/';
import {ThemeProvider, styled, Colors} from '@styles';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  body: Block[];
  hero: Block[];
}

const ButtonContainer = styled.div.withConfig({
  componentId: 'internshipsButtonContainer'
})`
  margin-bottom: 0.6em;
`;

const InternshipsHeroContainer = styled.div.withConfig({
  componentId: 'internshipsHeroContainer'
})`
  padding-top: 150px;
  .wide & {
    padding-top: 0;
    margin-bottom: -80px;
  }
`;

const Internships = ({data}: {data: Data}): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const {cms} = data;
  const {hero, body, searchDescription, seoTitle, canonicalUrl} = cms.page;
  const {header, image: fullWidthImage} = getDataFromBlockTypes(hero);
  const {intro, detail, testimonial, iconList} = getDataFromBlockTypes(body);
  const [isHover, hoverProps] = useHover();
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          fg: Colors.Charcoal,
          bg: Colors.Transparent,
          ctaIconFg: Colors.Blue,
          ctaIconBg: Colors.Blue,
          minPadding: '2em',
          maxPadding: '4em',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '540px',
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <Hero heroSectionType="medium" makeSectionShortTall altHero>
              <InternshipsHeroContainer>
                <ButtonContainer>
                  <BackLink
                    href="/careers/"
                    filledOnHover={true}
                    isHover={isHover}
                    {...hoverProps}
                  >
                    Back to Careers
                  </BackLink>
                </ButtonContainer>
                <HeroSectionCallout heroProps={[header, {}]} />
              </InternshipsHeroContainer>
            </Hero>
          </ThemeProvider>
          <FullWidthImageSection {...fullWidthImage} />
          {intro && <OneImageWithContentSection {...intro} />}
          <TwoImagesWithContentSection
            {...detail}
            pagelinkBlock={{	
              title: detail.pagelinkBlock.title,	
              page: {	
                url: `${detail.pagelinkBlock.page.url}/?keyword=&team=0001&location=`,	
              },	
            }}
          />
          <TestimonialSection
            {...testimonial}
            withBg={testimonial.testimonialBlock.bgimage}
          />
          <ThemeProvider
            theme={{
              bg: Colors.FogLight,
              maxPadding: '8em 0',
              minPadding: '4em 2em',
            }}
          >
            <IconListSection
              header={iconList.header}
              minMargin="0 auto"
              margin="0 auto"
              forceMaxWidth
              copy={iconList && iconList.subheader ? iconList.subheader : ''}
            />
          </ThemeProvider>
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default Internships;

export const query = graphql`
  fragment InternshipFragment on CMS_InternshipPage {
    seoTitle
    searchDescription
    canonicalUrl
    title
    hero {
      ...headerBlockFragment
      ...heroCmsImageFragment
    }
    body {
      ...sectionBodyImageFragment
      ...sectionBodyTwoImagesFragment
      ...testimonialSimpleBlockFragment
      ...headerBlockFragment
    }
  }
  query Internship {
    cms {
      page(url: "/home/careers/internships/") {
        ...InternshipFragment
      }
    }
  }
`;
